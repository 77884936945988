<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, reactive, watch, ref } from 'vue';
import { DEFAULT_OTP_EXPIRY_TIME, RESEND_CODE_TIMEOUT_DURATION } from 'ah-common-lib/src/helpers/otp';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { otpField } from 'ah-common-lib/src/form/models';
import { MFARequest, MFAResponse, MFAState, MFAType, AuthErrorCodes } from 'ah-api-gateways';
import { useServices } from 'bd-common/src/services';
import { tap } from 'rxjs/operators';
import { debounce, DebouncedFunc } from 'lodash';

const emit = defineEmits<{
  (e: 'update:value', value: string): void;
  (e: 'update:status', value: MFAState): void;
}>();

const props = withDefaults(
  defineProps<{
    staticMFA?: boolean | string;
    destination?: string;
    autoRequest?: string | boolean;
    value?: string;
    mfa: MFARequest;
  }>(),
  { staticMFA: false, autoRequest: false }
);

const mfaResponse = ref<MFAResponse>();

const mfaState = ref(MFAState.NOT_REQUESTED);

const isResendCodeButtonDisabled = ref(false);

let codeExpiryTimeout = 0;

const mfaForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'mfaForm',
    fieldType: 'form',
    fields: [otpField('code', '', { hideErrors: true, fieldWrapperClass: 'col col-12 mb-0' })],
  }),
  validation: null,
});

const debouncedSubmit: DebouncedFunc<(value: string) => void> = debounce(submitCode, 300);

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === `refreshMFA-${props.mfa.type}`) {
      refreshMFA();
    }
  },
}).manager;

const services = useServices();

const isEmail = computed(() => props.mfa.type === MFAType.EMAIL_OTP);

const showResendCode = computed(
  () => mfaState.value !== MFAState.SUCCESS && requestManager.requestStates[`submitMFA-${props.mfa.type}`] !== 'pending'
);

function mfaRequest() {
  return services.auth.refreshMfa(props.mfa).pipe(tap(setResponse));
}

function checkMfaRequest(value: string) {
  return services.auth.mfa(value, props.mfa).pipe(tap(setResponse));
}

function setResponse(res: MFAResponse) {
  mfaResponse.value = { ...res };
  mfaState.value = res.state;
}

function setError(e: any) {
  if ([AuthErrorCodes.FAILED_MFA_VALUE].includes(e.response?.data?.code)) {
    mfaState.value = MFAState.INVALID;
  } else {
    mfaState.value = MFAState.EXPIRED;
  }
}

function submitCode(value: string) {
  requestManager.currentOrNew(`submitMFA-${props.mfa.type}`, checkMfaRequest(value)).subscribe(setResponse, setError);
}

function refreshMFA() {
  if (mfaState.value === MFAState.SUCCESS) {
    return;
  }
  mfaForm.form.code = [];
  requestManager.currentOrNew(`refreshMFA-${props.mfa.type}`, mfaRequest()).subscribe(setResponse, setError);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    const value = (mfaForm.form.code ?? []).join('');
    emit('update:value', value);
    if (value.length >= 6 && props.autoRequest !== false) {
      debouncedSubmit(value);
    }
  }
}

function resendCode() {
  isResendCodeButtonDisabled.value = true;

  refreshMFA();

  setTimeout(() => {
    isResendCodeButtonDisabled.value = false;
  }, RESEND_CODE_TIMEOUT_DURATION);
}

function clearTimeouts() {
  if (codeExpiryTimeout > 0) {
    clearTimeout(codeExpiryTimeout);
    codeExpiryTimeout = 0;
  }
  debouncedSubmit.cancel();
}

const minutesToExpire = computed(() => {
  if (mfaResponse.value?.expiresSeconds) {
    return Math.floor(mfaResponse.value.expiresSeconds / 60);
  }
  return DEFAULT_OTP_EXPIRY_TIME;
});

watch(mfaState, () => {
  setState(getChildModel(mfaForm.form, 'code')!, 'inputClass', `form-control ${mfaState.value.toLowerCase()}`);
  emit('update:status', mfaState.value);
  if (mfaState.value === MFAState.SUCCESS) {
    setState(mfaForm.form, 'readonly', true);
  }
});

onBeforeMount(refreshMFA);
onBeforeUnmount(clearTimeouts);
defineExpose({
  setStatus: (status: MFAState) => {
    mfaState.value = status;
  },
  refreshMFA,
  setError,
});
</script>

<template>
  <div>
    <div v-if="mfaState === MFAState.NOT_REQUESTED">
      <p class="text-center description-text">
        To proceed, you must verify your {{ isEmail ? 'email address' : 'phone number' }}
        <span v-if="destination"> ending in {{ destination }}</span
        >.
      </p>
      <p class="text-center">
        <VButton
          :loading="requestManager.requestStates[`refreshMFA-${props.mfa.type}`] === 'pending'"
          @click="refreshMFA"
        >
          Send {{ isEmail ? 'Email' : 'SMS code' }}
        </VButton>
      </p>
    </div>
    <div v-else-if="requestManager.requestStates[`refreshMFA-${props.mfa.type}`] === 'pending'" class="loading-wrapper">
      <LoadingIcon class="loading-icon" />
    </div>
    <div v-else>
      <p class="resend-code-text">
        A 6-digit SMS verification code has been sent to your
        {{ mfa.type === MFAType.EMAIL_OTP ? 'email address' : 'phone number'
        }}<span v-if="destination">ending in {{ destination }}</span
        >. The code will be valid for {{ minutesToExpire }} {{ minutesToExpire === 1 ? 'minute' : 'minutes' }}.
      </p>
      <p v-if="staticMFA" class="text-secondary text-center">For testing purposes, code is always 123456</p>
      <ValidatedForm :fm="mfaForm.form" :validation.sync="mfaForm.validation" @form-event="onFormEvent" />
      <p v-if="mfaState !== MFAState.SUCCESS" class="text-error text-center mt-5">
        Didn't receive the message?
        <a
          href="#"
          @click.prevent="resendCode"
          class="resend-code"
          :class="{ disabled: isResendCodeButtonDisabled }"
          :disabled="requestManager.requestStates.refreshOtp === 'pending'"
        >
          {{
            requestManager.requestStates[`refreshMFA-${props.mfa.type}`] !== 'pending' ? 'Resend code.' : 'Resending...'
          }}
        </a>
      </p>
      <div class="text-center" v-else-if="showResendCode">
        Didn’t receive the message?
        <a href="#" @click.prevent="resendCode" class="resend-code" :class="{ disabled: isResendCodeButtonDisabled }"
          >Resend code.</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-wrapper {
  text-align: center;
  font-size: 3em;
}

::v-deep {
  .form-control.expired {
    @include form-control-invalid-styling;
  }
}
.resend-code {
  &.disabled {
    @include themedTextColor($color-bdDarkGrey);
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: underline;
  }
}
</style>
